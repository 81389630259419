import React, { useEffect, useState } from "react";
import About from "../components/About";
import backgroundImage from "../assets/image/homeground.jpeg";
import { ReactComponent as YoutubeIcon } from "../assets/icons/icons8-youtube.svg";
import EventsComponent from "../components/EventComponent";
import ImageSlider from "../components/ImageSlidder";
import Explore from "../components/Explore";
import QuickLink from "../components/QuickLink";
import { Link } from "react-router-dom";
import HomeModal from "../components/HomeModal";
import { firebase } from "../db/Firebase";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMediumOrLarger, setIsMediumOrLarger] = useState(false);
  const [campusTour, setCampusTour] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleResize() {
      setIsMediumOrLarger(window.innerWidth >= 768);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsModalOpen(isMediumOrLarger);
  }, [isMediumOrLarger]);
  useEffect(() => {
    const campusTourRef = firebase.database().ref("campusTour");
    campusTourRef.on("value", (snapshot) => {
      const tours = snapshot.val();
      const toursList = [];
      for (let id in tours) {
        toursList.push({ id, ...tours[id] });
      }
      setCampusTour(toursList);
      setLoading(false); 
    });
  }, []);
  return (
    <div>
      {isModalOpen && <HomeModal />}
      <div className="mt-16">
        <ImageSlider />
      </div>
      <About />
      <section
        className="relative bg-cover bg-center text-white py-40"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: "fixed",
          minHeight: "600px",
        }}
      >
        <div className="container  flex flex-wrap items-start">
          <div className="w-full md:w-1/2 p-6 bg-[#0c468094] bg-opacity-40 py-12">
            <h2 className="text-base mb-3">
              ST. THOMAS' INTERNATIONAL SCHOOL AT A GLANCE
            </h2>
            <h1 className="text-3xl font-semibold mb-6">
              We provide opportunities to succeed
            </h1>
            <p className="mb-6">
              We inspire students to recognize the benefits of collaboration,
              cultivate habits of responsibility and self-discipline, and
              advocate for initiative, endeavor, and the application of
              individual judgment.{" "}
            </p>
            <p className="mb-6">
              We present the school as a servant of the community, acknowledging
              its rightful share of responsibility in preparing each child to
              find their place in their chosen occupation and society. We
              instill values that serve as guiding principles to nurture better
              citizens for tomorrow.{" "}
            </p>
            <button className="bg-transparent border border-[#e77716] mt-5 py-2 px-4 rounded uppercase text-sm font-semibold tracking-wider hover:bg-red-500 hover:text-black transition ease-in-out duration-300">
              <Link to="/about/schoolMoto">Learn More</Link>
            </button>
          </div>

          {/* Right side YouTube round button and text */}

          <div className="w-full md:w-1/2 flex flex-col items-center pt-40">
            {(campusTour.length > 0
              ? campusTour
              : [{ title: "#", placeholder: true }]
            ).map((link, index) => (
              <React.Fragment key={index}>
                <a
                  href={link.title}
                  target={link.placeholder ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                  className="bg-[#e77716] hover:bg-red-700 font-bold p-4 rounded-full transition ease-in-out duration-300 flex items-center justify-center w-16 h-16"
                >
                  <YoutubeIcon className="w-8 h-8" />
                </a>
                <span className="mt-2 text-white font-semibold">
                  {link.placeholder ? "Watch Campus Tour" : "Watch Campus Tour"}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
      <EventsComponent />
      <Explore />
      <QuickLink />
    </div>
  );
}

export default Home;
