import React from "react";
import Co_Corricular from "../../assets/image/co-corricular.jpg"; 

const CoCorricular = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={Co_Corricular}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[700px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; BEYOND ACADEMICS &gt; CO-CURRICULAR ACTIVITIES
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Co-curricular Activities
          </h1>
          <p className="text-base font-poppins my-3">
            St. Thomas International School in Varanasi is dedicated to
            providing a comprehensive education that not only emphasizes
            academic excellence but also offers an array of co-curricular
            activities for the holistic development of our students.
          </p>
          <p className="text-base font-poppins my-3">
            Co-curricular activities play a crucial role in nurturing various
            facets of a student's personality, including their intellectual,
            emotional, social, moral, and aesthetic growth. These activities
            complement the theoretical knowledge imparted in the classroom by
            offering practical experiences that solidify learning. While the
            classroom environment focuses on the rational development of the
            student, co-curricular activities enhance aesthetic appreciation,
            character building, spiritual and physical well-being, moral values,
            and creativity.
          </p>
          <p className="text-base font-poppins my-3">
            Our diverse program of activities provides students with the
            opportunity to discover new interests and find joy in learning
            outside the traditional academic setting. By engaging students in
            these activities, we aim to enrich their school experience, improve
            their satisfaction with school life, and develop their personal
            skills.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoCorricular;
