import React, { useState } from "react";
import Contact from "../assets/image/schoolmain.jpg";
import { firebase } from "../db/Firebase";

const ContactUs = () => {
  const [showMapModal, setShowMapModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [option, setOption] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const schoolAddress = "Damodarpur, Munari Road, Sarnath, Varanasi - 221007";
  const mapUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    schoolAddress
  )}&output=embed`;

  const handleGetDirections = () => setShowMapModal(true);
  const handleCloseMapModal = () => setShowMapModal(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!name || !email || !mobile || !option || !message) {
      alert("All fields are mandatory. Please fill them out.");
      return;
    }
    const optionsMap = {
      1: "Admission",
      2: "Job Enquiry",
      3: "Others",
    };

    const data = { name, email, mobile, option: optionsMap[option], message };
    const db = firebase.database().ref("contactFormData");

    db.push(data)
      .then(() => {
        alert("Form submitted successfully!");
        setName("");
        setEmail("");
        setMobile("");
        setOption("");
        setMessage("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setIsLoading(false);
      });
  };
  return (
    <div className="mt-20  font-poppins">
      <img
        src={Contact}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[700px]"
      />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; Contact Us
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5">
          Contact Us
        </h1>
      </div>
      <div className="container  py-20 ">
        <div className="flex flex-wrap ">
          <div className="md:w-1/2 w-full px-4 md:px-20 ">
            <h5 className="  text-red-700 flex items-center mb-4 text-xl font-bold">
            
              Contact Details
            </h5>
            <p className="mb-4">
              <span >Address:</span> {schoolAddress}
            </p>
            <a href="tel:+15422595193" className="block mb-4">
              <span >Number:</span> 0542-2595193
            </a>
            <p className="mb-4">
              <span >Email:</span>{" "}
              stis.varanasi@gmail.com
            </p>
            <h5 className=" text-red-700 flex items-center mb-4 text-xl font-bold">
              Working Hours 
            </h5>
            <h5 className=" text-red-700 flex items-center mb-4 text-xl font-bold">
              Summer (April - September)
            </h5>
            <p className="mb-4">Monday to Saturday: 8:00 AM - 2:00 PM</p>
            {/* <p className="mb-4">Saturday: 10:00 AM - 2:00 PM</p> */}
            <p>Sunday: Closed</p>
            <h5 className=" text-red-700 flex items-center mb-4 text-xl font-bold">
            Winter (October - March)
            </h5>
            <p className="mb-4">Monday to Saturday: 9:00 AM - 3:00 PM</p>
            {/* <p className="mb-4">Saturday: 9:00 AM - 2:00 PM</p> */}
            <p>Sunday: Closed</p>
        
            <div className="text-center md:text-left">
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-green-700 flex  my-2 "
                type="button"
                onClick={handleGetDirections}
              >
              
                Get Directions
              </button>
            </div>
          </div>

          <div className="md:w-1/2 w-full px-4 bg-[#0c4680]">
            <form onSubmit={handleSubmit}>
              <h5 className="text-lg font-medium text-white mb-4 py-5">
                Send Us A Message
              </h5>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-400 rounded bg-[#0c4680] text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-700 focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-400 rounded bg-[#0c4680] text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-700 focus:outline-none"
                />
              </div>

              <div className="mb-4 flex flex-wrap -mx-2">
                <div className="w-full md:flex-1 px-2 mb-4 md:mb-0 flex items-center">
                  <div className="flex border border-gray-400 rounded w-full">
                    <span className="px-4 py-2 border-r border-gray-400 bg-[#0c4680] text-white">
                      +91
                    </span>
                    <input
                      type="tel"
                      placeholder="Enter phone number"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="w-full px-4 py-2 bg-[#0c4680] text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-700 focus:outline-none"
                      pattern="[0-9]{10}"
                      title="Phone number should be 10 digits"
                    />
                  </div>
                </div>
                <div className="w-full md:flex-1 px-2">
                  <select
                    aria-label="Select Subject"
                    value={option}
                    onChange={(e) => setOption(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-400 rounded bg-[#0c4680] text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-700 focus:outline-none"
                  >
                    <option value="">Select an Option</option>
                    <option value="1">Admission</option>
                    <option value="2">Job Enquiry</option>
                    <option value="3">Others</option>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <textarea
                  rows="3"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-400 rounded bg-[#0c4680] text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-700 focus:outline-none"
                ></textarea>
              </div>
              <div className="text-center">
                <button
                  className="bg-green-500 hover:bg-green-900 text-white font-bold py-2 px-4 rounded md:my-5"
                  type="submit"
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Tailwind Modal for Map */}
        {showMapModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-600 bg-opacity-50 flex">
            <div className="relative p-4 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
              <div className="flex items-center justify-between">
                <div className="text-lg font-medium">Location Map</div>
                <button
                  onClick={handleCloseMapModal}
                  className="text-gray-700 font-bold hover:text-gray-900"
                >
                  &times;
                </button>
              </div>
              <div className="mt-4">
                <iframe
                  title="Location Map"
                  src={mapUrl}
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                ></iframe>
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleCloseMapModal}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
