import React from "react";
import Logo from "../assets/image/thomas_logo.png";

function About() {
  return (
    <div className="container mx-auto px-4 my-10">
      <div className="flex flex-col items-center gap-5">
        <div className="mb-4">
          <img
            src={Logo}
            alt="School Logo"
            className="w-16 md:w-20 lg:w-20 xl:w-20 2xl:w-20"
          />
        </div>
        <div className="text-center w-3/4 px-4 ">
          <p
            className="text-sm sm:text-md md:text-lg lg:text-xl font-pt-sans"
            style={{ color: "#373636", paddingBottom: "20px" }}
          >
            St. Thomas International School is a Premium Co-Ed English Medium
            School, Affiliated to Central Board of Secondary Educational (CBSE) New
            Delhi. It has been established by Mukti Mission which is an
            autonomous, charitable, non-profit, educational institution
            established since 2002.  St. Thomas International School is a pioneer
            in the field of educational measurement, aptitude testing and
            psychological & educational research.{" "}
          </p>

          <div className="w-10 h-1 bg-orange-600 mx-auto"></div>
        </div>
      </div>
    </div>
  );
}

export default About;
