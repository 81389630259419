import React from "react";
import Vission from "../../assets/image/vision.jpeg";

const VissionMission = () => {
  return (
    <div className=" mt-16 mb-10">
      <img
        src={Vission}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[700px]  object-cover"
      />
      <div className="bg-white p-8">
        <div className=" mx-5 md:mx-20">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; About Us &gt; VISION & MISSION
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Vision & Mission
          </h1>
          <h2 className="text-base font-semibold underline py-5">Vission</h2>
          <p className="text-base font-poppins ">
            Our school vission is to provide a nurturing, learning environment to
            enable students to become responsible citizens, life-long learners,
            and good communicators through a challenging curriculum. The well
            reasearched, project based international learning methodology of the
            school is designed to prepare students to excel in the world’s
            finest colleges and universities.
          </p>

          <h2 className="text-base font-semibold underline py-5">Mission</h2>
          <p className="text-base font-poppins ">
            To provide an ATMOSPHERE to develop the individuals personality of
            each students and to inculcate in students and awareness of their
            rich culture heritage.
          </p>
          <ul className="font-light list-disc font-poppins pl-10 py-5">
            <li className="py-5">
              To provide a CLIMATE for a whole some and integrated up bringing-
              seeking to provide well acclimated, dynamic students, equipped
              with the valuable qualities of leadership and awareness.
            </li>
            <li className="py-5">
              To Provide OPPORTUNITIES for extra curriculum activities and games
              for the growth of sound, physical & mental health.
            </li>
            <li className="py-5">
              To recognize and to encourage talent of all kinds, and of all
              degrees and to endeavour to stretch the intellectual and creative
              capacity of individuals.
            </li>
            <li className="py-5">
              To enable students to acquire the tools and qualification
              necessary for earning their living and when appropriate for entry
              into skilled occupations and professions.
            </li>
            <li className="py-5">
              To recognize and accept differences in endowment and environment
              (physical, intellectual, spiritual and social) to hold every
              individual in esteem.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VissionMission;
