import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { firebase } from "../db/Firebase";

const EventCard = ({ event }) => (
  <Link to={`/news-events/${event.id}`} state={{ event }}>
    <div className="bg-[#0c4680] hover:bg-[#e77716] text-white shadow-lg flex h-24 my-4">
      <img
        src={event.images[0]}
        alt={event.title}
        className="h-full object-cover w-28"
      />
      <div className="flex flex-1 flex-col justify-center pl-4">
        <p className="text-sm font-semibold ">{event.date}</p>
        <h3 className="text-lg font-semibold ">{event.title}</h3>
      </div>
    </div>
  </Link>
);

const CalendarEventCard = ({ calendarEvents }) => (
  <div className="shadow-lg flex h-24 my-4 text-white ">
    <div className="bg-[#0c4680]  w-28 flex items-center justify-center">
      <p className="text-sm font-semibold">{calendarEvents.date}</p>
    </div>
    <div className="bg-[#e77716] flex flex-1 flex-col justify-center pl-4">
      <h3 className="text-lg font-semibold">{calendarEvents.title}</h3>
    </div>
  </div>
);

const EventsComponent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventsRef = firebase.database().ref("events");
    eventsRef.on("value", (snapshot) => {
      const eventsData = snapshot.val();
      if (eventsData) {
        const eventsList = Object.keys(eventsData).map((key) => ({
          id: key,
          ...eventsData[key],
        }));
        eventsList.sort((a, b) => b.createdAt - a.createdAt);
        setEvents(eventsList);
      } else {
        setEvents([]);
      }
    });
  }, []);

  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    const calendareventsRef = firebase.database().ref("calendarEvents");
    calendareventsRef.on("value", (snapshot) => {
      const events = snapshot.val();
      const eventsList = [];
      for (let id in events) {
        eventsList.push({ id, ...events[id] });
      }
      eventsList.sort((a, b) => b.createdAt - a.createdAt);
      setCalendarEvents(eventsList);
    });
  }, []);
  return (
    <div className="container mx-auto  my-10">
      <div className="flex flex-wrap mx-2 justify-center ">
        <div className="w-full md:w-2/5 px-2">
          <h2 className="text-3xl font-bold mb-4 text-[#0c4680] py-5">
            NEWS & EVENTS
          </h2>
          <div className="space-y-4">
            {events.slice(0, 3).map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
          <div className="justify-end flex py-10 pr-2">
            <Link
              to="/news-events/all"
              state={{ events: events }}
              className="text-[#0c4680] hover:text-[#e77716] font-bold"
              path="/news-events"
            >
              VIEW ALL NEWS
            </Link>
            <span className="ml-2 bg-[#0c4680] text-white font-bold px-2 rounded-full">
              <FontAwesomeIcon icon={faCaretRight} />
            </span>
          </div>
        </div>
        <div className="w-full md:w-2/5 px-2 ">
          <h2 className="text-3xl font-bold mb-4 text-[#e77716] py-5">
            CALENDAR EVENTS
          </h2>
          <div className="space-y-4">
            {calendarEvents.slice(0, 3).map((calendarEvents) => (
              <Link to="/calendar-events">
              <CalendarEventCard
                key={calendarEvents.id}
                calendarEvents={calendarEvents}
              />
              </Link>
            ))}
          </div>
          <div className="justify-end flex py-10 pr-2">
            <Link to="/calendar-events" className="text-[#e77716] font-bold">
              VIEW ALL EVENTS
            </Link>
            <span className="ml-2 bg-[#e77716] text-white font-bold px-2 rounded-full">
              <FontAwesomeIcon icon={faCaretRight} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventsComponent;
