import React from "react";
import Council from "../../assets/image/council.jpg";

const StudentCounsil = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={Council}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[700px]"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; BEYOND ACADEMICS &gt; STUDENT COUNCIL
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Student Council
          </h1>
          <p className="text-base font-poppins my-3">
            The Student Council is a body run by students under the guidance of
            adults, aimed at providing a platform for developing leadership
            skills through the organization and execution of school-related
            activities and service initiatives. It serves as a crucial link
            between the student body and the school administration, facilitating
            the exchange of ideas, interests, and concerns within the school
            community. Participation is open to any student keen on enhancing
            their leadership, organizational skills, event management, or
            overall engagement in school life.
          </p>
          <p className="text-xl my-3 font-bold">Objectives</p>
          <ul className=" list-disc font-poppins ">
            <li className="ml-10 py-1">
              Enhance communication between students, faculty, staff, and the
              wider community.
            </li>
            <li className="ml-10 py-1">
              Boost school spirit through various activities.
            </li>
            <li className="ml-10 py-1">Cultivate responsible citizenship.</li>
            <li className="ml-10 py-1">
              Foster positive relationships throughout the school environment.
            </li>
            <li className="ml-10 py-1">
              Strengthen the bond between students and teachers.
            </li>
            <li className="ml-10 py-1">
              Offer a venue for students to voice their opinions and ideas.
            </li>
            <li className="ml-10 py-1">
              Organize and oversee special events, community service projects,
              and celebrations.
            </li>
          </ul>
          <p className="text-xl my-3 font-bold">
            The Council is structured with 40 positions for both the Primary and
            Senior Sections, organized as follows
          </p>
          <ul className=" list-disc text-base font-poppins ">
            <li className="ml-10 py-1 ">
              Positions of leadership include Head Boy and Head Girl.
            </li>
            <li className="ml-10 py-1 ">
              Roles within the Student Council Officers encompass Sports
              Captain, Cultural Captain, Discipline Secretary, Environment
              Secretary, along with their Vice-Captains and Deputies.
            </li>
            <li className="ml-10 py-1 ">
              The assembly also comprises House Captains & Vice-Captains, in
              addition to Prefects
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StudentCounsil;
