import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import SchoolMoto from "./pages/about/SchoolMoto";
import VissionMission from "./pages/about/VisionMission";
import Director from "./pages/about/Director";
import Chairman from "./pages/about/Chairman";
import Affiliation from "./pages/about/Affiliation";
import Faculty from "./pages/about/Faculty";
import Curriculam from "./pages/academics/Curriculam";
import Methodology from "./pages/academics/Methodology";
import Assessment from "./pages/academics/Assessment";
import Primary from "./pages/academics/Primary";
import Secondary from "./pages/academics/Secondary";
import CoCorricular from "./pages/beyondEducation/CoCurricular";
import Community from "./pages/beyondEducation/Community";
import Clubs from "./pages/beyondEducation/Clubs";
import Sports from "./pages/beyondEducation/Sports";
import StudentCounsil from "./pages/beyondEducation/StudentCounsil";
import Visits from "./pages/beyondEducation/Visits";
import Facilities from "./pages/infrastructure/Facilities";
import Nursery from "./pages/admission/Nursery";
import AdmissionKg from "./pages/admission/AdmissionKg";
import AdmissionSenior from "./pages/admission/AdmissionSenior";
import Registration from "./pages/admission/Registration";
import Circulars from "./pages/Circular";
import Magazine from "./pages/Magazine";
import Toppers from "./pages/Toppers";
import Achievments from "./pages/Achievments";
import Vacancy from "./pages/Vacancy";
import ContactUs from "./pages/ContactUs";
import Gallery from "./pages/Gallery";
import Events from "./pages/EventSection";
import PublicDisclosure from "./pages/about/PublicDisclosure";
import EventsComponent from "./components/EventComponent";
import Elearning from "./pages/academics/Elearning";
import OnLineFee from "./components/OnlineFee";
import Principal from "./pages/about/Principal";
import CalendarWithEvents from "./components/Calendar";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/about/schoolMoto" element={<SchoolMoto />} />
          <Route path="/about/vission-mission" element={<VissionMission />} />
          <Route path="/about/management" element={<Director />} />
          <Route path="/about/chairman" element={<Chairman />} />
          <Route path="/about/principal" element={<Principal />} />
          <Route path="/about/affiliation" element={<Affiliation />} />
          <Route path="/about/faculty" element={<Faculty />} />
          <Route
            path="/mpd"
            element={<PublicDisclosure />}
          />

          <Route path="/academics/curriculam" element={<Curriculam />} />
          <Route
            path="/academics/teaching-methology"
            element={<Methodology />}
          />
          <Route
            path="/academics/assessement/pre-primary"
            element={<Assessment />}
          />
          <Route path="/academics/assessement/primary" element={<Primary />} />
          <Route
            path="/academics/assessement/secondary"
            element={<Secondary />}
          />
          <Route
            path="/academics/e-learning"
            element={<Elearning />}
          />

          <Route
            path="/beyond-academics/co-curricular"
            element={<CoCorricular />}
          />
          <Route path="/beyond-academics/community" element={<Community />} />
          <Route path="/beyond-academics/clubs" element={<Clubs />} />
          <Route
            path="/beyond-academics/student-counsil"
            element={<StudentCounsil />}
          />
          <Route path="/beyond-academics/sports" element={<Sports />} />
          <Route path="/beyond-academics/visits" element={<Visits />} />

          <Route path="/infrastructure/facilities" element={<Facilities />} />

          <Route path="/admission/nursery" element={<Nursery />} />
          <Route path="/admission/kg-ix" element={<AdmissionKg />} />
          <Route path="/admission/xi" element={<AdmissionSenior />} />
          <Route path="/admission/registration" element={<Registration />} />

          <Route path="/circulars" element={<Circulars />} />
          <Route path="/online-fee" element={<OnLineFee />} />
          <Route path="/school-magazine" element={<Magazine />} />
          <Route path="/school-toppers" element={<Toppers />} />
          <Route path="/school-achievments" element={<Achievments />} />
          <Route path="/school-vacancy" element={<Vacancy />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/news-events" element={<EventsComponent />} />
          <Route path="/calendar-events" element={<CalendarWithEvents />} />
          <Route path="/news-events/:eventId" element={<Events />} />
          <Route path="/news-events/all" element={<Events />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
