import React from "react";
import DirectorImg from "../../assets/image/director.jpg";

const Director = () => {
  return (
    <div className="mt-20 mb-20">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; About Us &gt; Management &gt; Director
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Director
        </h1>
        <div className="flex justify-center items-center py-5">
          <div className="border-2 border-gray-200 shadow-lg p-1">
            <img
              src={DirectorImg}
              alt="Vision and Mission"
              className=" h-80 w-[450px]"
            />
          </div>
        </div>
        
        <h1 className="text-4xl font-bold font-custom py-5 mb-2">
          Dr. Ashish{" "}
        </h1>
        <p
          className="text-base font-poppins font-bold pb-5"
          style={{ fontStyle: "italic" }}
        >
          {" "}
          “Somewhere, something incredible is waiting to be known.” -Carl Sagan
        </p>
        <p className="text-base font-poppins py-5">
          Dear Parents and well-wishers,
        </p>
        <p className="text-base font-poppins ">
          I came across this amazing quote quite suddenly when I was looking for
          some information in the internet and realized how relevant it is for
          all of us: children, parents and teachers. There is never any point in
          the life of a person when he can sit back and say. “There is nothing
          more to learn, I can relax with the comforting thought that the
          universe around me is an open book. I know it all.”
        </p>

        <p className="text-base font-poppins py-3">
          Education is not just about the subjects that are learnt and taught in
          school. Becoming educated is not restricted to being in school and
          then in college, gathering certificates and feeling proud of oneself.
          It is a lifelong exercise that can be unbelievably exciting if only we
          wish to jump onto the train of experience and take a trip to every
          conceivable place on the earth. Now we know that this cannot be done
          literally, so how do we manage it, first in school and then as we go
          on through the journey of life?
        </p>
        <p className="text-base font-poppins py-3">
          Foremost, we all need to be in agreement: children, teachers and
          parents have to understand that education is not restricted to lessons
          taught in the classroom from text books. Parents and teachers must
          encourage children to ask as many questions as they can. For it is in
          wanting to know that will result in actually getting to know..
        </p>
        <p className="text-base font-poppins py-3">
          The appetite for knowledge is not restricted to the child. It is also
          essential for a parent and a teacher to keep adding to their own
          knowledge bank by keeping their eyes and ears open and by reading as
          much as possible and ensuring that children develop these habits as
          well.
        </p>
        <p className="text-base font-poppins py-3">
          My true education was in library or I can say in words of Rita Mae
          Brown "When I got [my] library card, that was when my life began."
        </p>
        <p className="text-base font-poppins py-3 ">
          Besides all of the above, that add to children becoming truly
          educated, schools and teachers must ensure that children have a
          society of enriching experiences. Surprisingly, these experiences
          develop a child’s creativity and imagination and lead to something
          that is often thought to be more important than knowledge. Creativity
          and imagination make children grow up to be productive adults who then
          arrive as citizens of the world ready to make their own great
          contribution to mankind.
        </p>
        <p className="text-base font-poppins py-3 ">
          “Imagination is more important than knowledge. For knowledge is
          limited to all we now know and understand, while imagination embraces
          the entire world, and all there ever will be to know and understand. “
          - Albert Einstein
        </p>
        <p className="text-base font-poppins py-3 ">
          My message to the Principal, Staffs, Students and esteemed Parents of
          St. Thomas International School, Varanasi, is dedicated this year to a
          correct understanding of what education really means and let us all
          benefit from this understanding.
        </p>
      </div>
    </div>
  );
};

export default Director;
