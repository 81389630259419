import React from 'react'
import background from '../assets/image/fairwell.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faUtensils, faFutbol, faHandshake, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import British from '../assets/image/british.jpg'
import Npsc from '../assets/image/npsc.png'
import Microsoft from '../assets/image/mircosoftf.jpg'
import Ncc from '../assets/image/ncc.png'

function QuickLink() {
  return (
    <div className='flex flex-col'>
      <div style={{ 
          backgroundImage: `url(${background})`, 
          backgroundPosition: "center", 
          backgroundSize: "cover", 
          height: "500px",
          backgroundAttachment: 'fixed'
        }}>
        <div className='flex flex-col md:flex-row justify-center gap-8 md:gap-32 items-center text-white p-10 uppercase text-xs md:text-xl'
             style={{ 
               backgroundColor: "rgba(0, 0, 0, 0.5)", 
               height: "100%",
             }}>
          
          <div className="flex flex-col items-center hover:text-[#e77716] gap-2"> 
            <FontAwesomeIcon icon={faPaintBrush} size="3x" />
            <span >Admission</span>
          </div>
          
          <div className="flex flex-col items-center hover:text-[#e77716]"> 
            <FontAwesomeIcon icon={faNewspaper} size="3x" />
            <span>STS IN NEWS</span>
          </div>
          
          <div className="flex flex-col items-center hover:text-[#e77716]"> 
            <FontAwesomeIcon icon={faUtensils} size="3x" />
            <span>FOOD MENU OF THE MONTH</span>
          </div>
          
          <div className="flex flex-col items-center hover:text-[#e77716]"> 
            <FontAwesomeIcon icon={faFutbol} size="3x" />
            <span>SPORTS</span>
          </div>
          
          <div className="flex flex-col items-center hover:text-[#e77716]"> 
            <FontAwesomeIcon icon={faHandshake} size="3x" />
            <span>VACANCY</span>
          </div>
          
          <div className="flex flex-col items-center hover:text-[#e77716]">
            <FontAwesomeIcon icon={faPhone} size="3x" />
            <span>CONTACT US</span>
          </div>
          
        </div>
      </div>
      <div className='flex flex-col md:flex-row gap-5 md:gap-32 p-10 justify-center items-center'>
  <img src={British} alt="Description 1" className="w-[150px] h-[150px]" />
  <img src={Npsc} alt="Description 2" className="w-[150px] h-[150px]" />
  <img src={Microsoft} alt="Description 3" className="w-[150px] h-[150px]" />
  <img src={Ncc} alt="Description 4" className="w-[150px] h-[150px]" />
</div>

    </div>
  )
}

export default QuickLink
