import React from "react";
import ChairmanImg from "../../assets/image/chairman.png";

const Chairman = () => {
  return (
    <div className="mt-20 mb-20">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; About Us &gt; Chairman
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Chairman
        </h1>
        <div className="flex justify-center items-center py-5">
          <div className="border-2 border-gray-200 shadow-lg p-1">
            <img
              src={ChairmanImg}
              alt="Vision and Mission"
              className=" h-80 w-[450px]"
            />
          </div>
        </div>
        <h1 className="text-4xl font-bold font-custom py-5 mb-5">
          Dr.Rajesh Naithani
        </h1>
       
        <p className="text-base font-poppins py-5">
          Dear Parents and well-wishers,
        </p>
        <p className="text-base font-poppins ">
          It is a matter of pride to pen down the message for St. Thomas
          Internaonal School. My heart fills with immense pleasure as I perceive
          the progress being made for St. Thomas Internaonal School in such a
          short span of me. The seeds of an idea sown in has quicky comes to
          fruition, and the school is growing into strong sapling. It is the
          endeavour of the Mukti Mission Group to make the academic like, a
          smooth journey full of joy and discovery.{" "}
        </p>

        <p className="text-base font-poppins py-3">
          As we all are aware that great ideas begin with a commitment to the
          future. it is with a strong commitments to the quest for excellence in
          the field of education, that St. Thomas  was established and is making
          rapid progress in enchancing its potential and changing its outlook
          towards providing beer resources required to impart the best all round
          quality education laced with morality.
        </p>
        <p className="text-base font-poppins py-3">
          Strategic planning and pragmatic approach must be the rationale behind any
          experimentation in the field of education. it is a matter of pride for
          everyone associated with St. Thomas that we have evolved and
          successfully implemented a system which caters for the Social, moral,
          physical and emotional development of our children. I am extermely happy
          to note that the years of thoughtful and result oriented work has
          started yielding good returns. The high quality of work is visible in
          every undertaking.
        </p>
        <p className="text-base font-poppins py-3">
          I can assure you that St. Thomas will not be complacent with this
          achievement but strive harder to improve and sustain its level of
          efficiency. The vision, support and guidance of the Management Commitee
          continue to be a great source of inspiration for all our endeavours. The
          opportunity of collaboraon between teacher and students creates a
          harmonious and efficient environment that nurtures them for the
          society. Each one of my staff members given them the chance to work,
          to play, to dream and to achieve their goals.
        </p>
        <p className="text-base font-poppins py-3">
          There are challenges ahead of us, however, as I often say, ‘together we
          can truly make a great difference. ‘Keeping this in mind, the St.
          Thomas family continues to remain committed to achieve both our vision
          and mission Sincerely.
        </p>
      </div>
    </div>
  );
};

export default Chairman;
