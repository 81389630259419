// AssessmentTable.js
const AssessmentTable = () => {
  return (
    <div className="overflow-x-auto mt-8">
      <h2 className="text-2xl font-bold text-[#0c4680] mb-4">
        A. Scholastic Areas
      </h2>
      <h3 className="text-lg font-bold text-[#0c4680] mb-2">
        CLASSES: III - IX
      </h3>
      <div className="bg-white  shadow rounded font-poppins">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-[#0c4680]">
              <th scope="col" className="py-3 px-6 border border-gray-300">
                Term
              </th>
              <th scope="col" className="py-3 px-6 border border-gray-300">
                Months
              </th>
              <th scope="col" className="py-3 px-6 border border-gray-300">
                Marks (III)
              </th>
              <th scope="col" className="py-3 px-6 border border-gray-300">
                Marks (IV-V)
              </th>
              <th scope="col" className="py-3 px-6 border border-gray-300">
                Marks (VI-IX)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b text-black">
              <td className="py-4 px-6 border border-gray-300">
                Periodic Test - 1
              </td>
              <td className="py-4 px-6 border border-gray-300">July</td>
              <td className="py-4 px-6 border border-gray-300">30 Marks</td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
            </tr>
            <tr className="bg-white border-b text-black">
              <td className="py-4 px-6 border border-gray-300">
                Mid Term Examination
              </td>
              <td className="py-4 px-6 border border-gray-300">September</td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
              <td className="py-4 px-6 border border-gray-300">60 Marks</td>
              <td className="py-4 px-6 border border-gray-300">80 Marks</td>
            </tr>
            <tr className="bg-white border-b text-black">
              <td className="py-4 px-6 border border-gray-300">
                Periodic Test -2
              </td>
              <td className="py-4 px-6 border border-gray-300">December</td>
              <td className="py-4 px-6 border border-gray-300">30 Marks</td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
            </tr>
            <tr className="bg-white border-b text-black">
              <td className="py-4 px-6 border border-gray-300">
                Annual Examination
              </td>
              <td className="py-4 px-6 border border-gray-300">
                February - March
              </td>
              <td className="py-4 px-6 border border-gray-300">40 Marks</td>
              <td className="py-4 px-6 border border-gray-300">60 Marks</td>
              <td className="py-4 px-6 border border-gray-300">80 Marks</td>
            </tr>
          </tbody>
        </table>
        <h3 className="text-lg font-bold text-[#800000] py-5">
          {" "}
          Assessment and Weightage (for class III):
        </h3>
        <div className="bg-white  shadow rounded">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Mid Term / Annual Examination
                </td>
                <td className="py-4 px-6 border border-gray-300">40 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Weightage taken from Periodic Test -1 / Periodic Test -2
                </td>
                <td className="py-4 px-6 border border-gray-300">15 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Notebook Submission{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">05 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Subject Enrichment Activity{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">15 marks</td>
              </tr>
              <tr className="bg-white border-b text-black font-bold">
                <td className="py-4 px-6 border border-gray-300 text-end">
                  Total{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">75 marks</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="text-lg font-bold text-[#800000] py-5">
          Assessment and Weightage (for classes IV-V):
        </h3>
        <div className="bg-white  shadow rounded">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Mid Term / Annual Examination
                </td>
                <td className="py-4 px-6 border border-gray-300">60 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Weightage taken from Periodic Test -1 / Periodic Test -2
                </td>
                <td className="py-4 px-6 border border-gray-300">20 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Notebook Submission{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">05 marks</td>
              </tr>
              <tr className="bg-white border-b text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Subject Enrichment Activity{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">15 marks</td>
              </tr>
              <tr className="bg-white border-b text-black font-bold">
                <td className="py-4 px-6 border border-gray-300 text-end">
                  Total{" "}
                </td>
                <td className="py-4 px-6 border border-gray-300">100 marks</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="text-lg font-bold text-[#800000] py-5">
          Assessment and Weightage (for classes VI-VIII):
        </h3>
        <div className="bg-white shadow rounded text-black">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody className="text-black">
              <tr className="text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Mid Term / Annual Examination
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right text-black ">
                  80 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  Internal Assessment
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right"></td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  <div className="flex flex-col">
                    <span>Periodic Assessment</span>
                    <ul className="list-disc ml-6">
                      <li>Pen and Paper Test</li>
                    </ul>
                    <span className="text-xs pl-6">
                      (Weightage taken from Periodic Test-1 & Periodic Test-2)
                    </span>
                  </div>
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  <div className="flex flex-col">
                    <span>Periodic Assessment</span>
                    <ul className="list-disc ml-6">
                      <li>Multiple Assessment</li>
                    </ul>
                  </div>
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300 text-black">
                  Notebook Submission
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300 text-black">
                  Subject Enrichment Activity
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr className="font-bold">
                <td className="py-4 px-6 border border-gray-300 text-right">
                  Total
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  100 marks
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="text-lg font-bold text-[#800000] py-5">
          Assessment and Weightage (for classes IX-X):
        </h3>
        <div className="bg-white shadow rounded text-black">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody className="text-black">
              <tr className="text-black">
                <td className="py-4 px-6 border border-gray-300">
                  Annual Examination
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right text-black ">
                  80 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  Internal Assessment
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right"></td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  <div className="flex flex-col">
                    <span>Periodic Assessment</span>
                    <ul className="list-disc ml-6">
                      <li>Pen and Paper Test</li>
                    </ul>
                  </div>
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300">
                  <div className="flex flex-col">
                    <span>Periodic Assessment</span>
                    <ul className="list-disc ml-6">
                      <li>Multiple Assessment</li>
                    </ul>
                  </div>
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300 text-black">
                  Portfolio
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 border border-gray-300 text-black">
                  Subject Enrichment Activity
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  5 marks
                </td>
              </tr>
              <tr className="font-bold">
                <td className="py-4 px-6 border border-gray-300 text-right">
                  Total
                </td>
                <td className="py-4 px-6 border border-gray-300 text-right">
                  100 marks
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className="text-[#800000] underline py-5">Please Note:</p>
          <ul className="list-disc list-inside">
            <li>
              Notebook Submission (5 marks) will include parameters like
              regularity, assignment completion, and neatness of the notebook.
            </li>
            <li>
              Multiple Assessment and Subject enrichment activities will include
              activities like: Speaking and Listening skills/ Practical Lab
              work/ Maths Lab Practical/ Map Work/ Project Work and many more.
            </li>
            <li>
              Portfolio will include student’s performance records/documents and
              a collection of their learning achievements. Portfolio submission
              (5 marks) will include parameters like neatness, creativity,
              visual appeal, student’s learning and completion on time.
            </li>
            <li>
              Students of Class X and XII will appear for 2 sets of Pre Board
              Examinations after the completion of the syllabus in addition to
              Periodic Test -1 & Mid Term Examination
            </li>
          </ul>
        </div>
        <h2 className="text-2xl font-bold text-[#0c4680]  py-5">
          B. Co-Scholastic Areas
        </h2>
        <ul className="list-disc list-inside">
          <li>Grades are awarded on a 5-point grading scale.</li>
          <li>Grades are awarded on a 5-point grading scale.</li>
          <li>
            Discipline: The students will also be assessed for discipline which
            will be based on factors like attendance, sincerity, behaviour,
            values, tidiness, respectfulness for rules and regulations, attitude
            towards peers, teachers, other school staff, parents, society and
            nation.
          </li>
        </ul>
        <h3 className="text-lg font-bold text-[#800000] py-5">
          {" "}
          CLASS – XI and XII
        </h3>
        <ul className="list-disc list-inside">
          <li>
            The students of Classes XI & XII will be assessed regularly through
            Periodic Test-1, Mid Term examination, Periodic Test-2, Annual
            examination (for Class XI) and Board examination (for Class XII).
          </li>
          <li>
            The Annual examination for Class XII will be conducted by CBSE
            whereas for Class XI it will be conducted by the school as per the
            guidelines issued by CBSE.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AssessmentTable;
