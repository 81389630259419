import React from "react";
import PrimaryImg from "../../assets/image/primary.jpg";

const Primary = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={PrimaryImg}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[500px]  "
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt;ASSESSMENT &gt; CLASSES: I - II
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            CLASSES: I - II
          </h1>
          <p className="text-base font-poppins my-3">
            To avoid stress and to make learning an enjoyable experience through
            acknowledging and aiming to meet the students’ diverse needs -
            physical, social, intellectual, aesthetic and cultural, the primary
            school focusses on the student’s need to know, understand and
            develop the ability to experiment, explore and discover as part of
            their schooling through an interactive environment.
          </p>
          <p className="text-base font-poppins my-3">
            The four test cycles help the child to better adjust and adapt to
            the learning environment and assist the teachers and parents alike
            to mould the above mentioned environment to the students' needs. The
            students are assessed internally on quarterly basis (4 Test Cycles)
            through a checklist of assignments and observational activities in
            order to develop a sense of curiosity and the desire to explore.
            This helps evaluate their areas of achievements and to work on their
            weak areas for further improvement.{" "}
          </p>
          
        </div>
      </div>
    </div>
  );
};

export default Primary;
