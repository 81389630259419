import React from "react";
import Faculties from "../../assets/image/faculty.jpg";

const Faculty = () => {
  return (
    <div className="mt-16 mb-10">
      <img src={Faculties} alt="School motto banner" className="w-full h-[200px] md:h-[700px] object-cover" />
      <div className="bg-white p-8">
        <div className=" mx-5 md:mx-20">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; About Us &gt; Faculty
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Faculty
          </h1>
          <p className="text-base font-poppins my-3">
            Teachers play a crucial role in shaping a child’s personality,
            inducting positive thoughts, simulating analytical thinking and
            inculcating decision-making ability.
          </p>
          <p className="text-base font-poppins my-3">
            The faculty at St. Thomas’ has been prudently selected on the basis
            of their subject expertise and compassion for children. Their
            competence is persistently enriched through regular exposure to
            in-service training, refresher programmes, microteaching sessions
            cum workshops and seminars on latest teaching technology and
            pedagogical advancement. The faculty members make continuous efforts
            to ensure that the students must get expertise in practical
            implementation of tools they have in their curriculum.
          </p>
          <p className="text-base font-poppins my-3">
            We have over 200 committed and qualified teachers and supporting
            staff whose passion for excellence is apparent in their domains.
            Their vast experience combined with the constant training offered to
            them by CBSE, NCERT, UNESCO, Sankalp Sahodaya, British Council and
            many renowned agencies, result in lessons being taught differently,
            children being encouraged to come up with creative responses, and
            resulting in learning which is fun-filled, interactive and student
            centric.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faculty;
