import React from "react";
import MethodologyImg from "../../assets/image/methodology.jpeg";

const Methodology = () => {
  return (
    <div className="mt-16 mb-10">
      <img src={MethodologyImg} alt="School motto banner" className="w-full h-[200px] md:h-[700px] object-cover" />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt; Teaching Methodology
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Teaching Methodology
          </h1>
          <p className="text-base font-poppins my-3">
          We engage our students through a variety of teaching methods. We stimulate curiosity, invite participation, challenge assumptions and address current world issues. 
          </p>
          <p className="text-base font-poppins my-3">
          Students learn through teacher instruction, smart classes, PowerPoint presentations, lab-work, group activities and field trips. Seminars and workshops also form an integral part of the school curriculum. Teachers use child-centric methodology which instils confidence, resourcefulness, and development of critical and analytical skills.
          </p>
          <p className="text-base font-poppins my-3">
          Our faculty uses modern teaching methodologies to make learning a pleasure. ‘Smart Class’ technology has been adopted wherein classrooms are equipped with Interactive Boards loaded with tons of information for all classes as per the CBSE curriculum. This audio-visual teaching helps to reinforce classroom concepts and brings greater clarity of thought and understanding. The methodology is experiential; children get hands-on experience related to various concepts. 
          </p>
          <p className="text-base font-poppins my-3">
          St. Thomas International School, Sarnath Varanasi believes in inspiring children to inquire and seek responses themselves. The idea is to open their minds first, unleash their creativity and then help them build on it.
          </p>
          <p className="text-base font-poppins my-3">
          We firmly believe that "Children must be taught how to think, not what to think".
          </p>
       
        </div>
      </div>
    </div>
  );
};

export default Methodology;
